import { faGithub, faGitlab, faKeybase, faLinkedin, faTwitterSquare } from "@fortawesome/free-brands-svg-icons";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import * as React from "react";

import HTMLContent from "./HTMLContent";
import Layout from "./Layout";
import Mailto from "./Mailto";
import PostPreview from "./PostPreview";

export interface Props {
  data: {
    site: {
      siteMetadata: {
        email: string;
      };
    };
    bio: {
      html: string;
      frontmatter: {
        status: string;
      };
    };
    allMarkdownRemark: {
      edges: Array<{
        node: {
          id: string;
          fields: {
            slug: string;
          };
          frontmatter: {
            title: string;
            author: string;
            description: string;
            lang: string;
            templateKey: string;
            published: string;
            publishedDate: string;
            tags: string[];
          };
        };
      }>;
    };
  };
}

const HomePage: React.SFC<Props> = ({ data }) => {
  const posts = data.allMarkdownRemark.edges.map(p => <PostPreview key={p.node.id} post={p.node} />);

  return <Layout>
    <div className="hero has-background-info-45 is-bold">
      <div className="hero-body white-hero-body">
        <section className="container is-narrow">
          <h1 className="title">David Sferruzza</h1>
          <h2 className="subtitle">{data.bio.frontmatter.status}</h2>
          <HTMLContent html={data.bio.html} />
        </section>
      </div>
    </div>
    <div className="container is-narrow">
      <section className="section">
        <h1 className="title">Where to find me?</h1>
        <div className="columns is-vcentered is-mobile is-multiline">
          <div className="column is-one-third-mobile has-text-centered">
            <Mailto email={data.site.siteMetadata.email} obfuscate={true} title="Send me an email" className="icon is-large email">
              <FontAwesomeIcon size="3x" icon={faEnvelopeSquare} />
            </Mailto>
          </div>
          <div className="column is-one-third-mobile has-text-centered">
            <a href="https://twitter.com/d_sferruzza" title="Follow me on Twitter" className="icon is-large twitter" target="_blank" rel="nofollow noopener">
              <FontAwesomeIcon size="3x" icon={faTwitterSquare} />
            </a>
          </div>
          <div className="column is-one-third-mobile has-text-centered">
            <a href="https://www.linkedin.com/in/david-sferruzza/" title="Check my LinkedIn profile" className="icon is-large linkedin" target="_blank" rel="nofollow noopener">
              <FontAwesomeIcon size="3x" icon={faLinkedin} />
            </a>
          </div>
          <div className="column is-one-third-mobile has-text-centered">
            <a href="https://github.com/dsferruzza" title="Go to my GitHub profile" className="icon is-large github" target="_blank" rel="nofollow noopener">
              <FontAwesomeIcon size="3x" icon={faGithub} />
            </a>
          </div>
          <div className="column is-one-third-mobile has-text-centered">
            <a href="https://gitlab.com/dsferruzza" title="Go to my GitLab profile" className="icon is-large gitlab" target="_blank" rel="nofollow noopener">
              <FontAwesomeIcon size="3x" icon={faGitlab} />
            </a>
          </div>
          <div className="column is-one-third-mobile has-text-centered">
            <a href="https://keybase.io/dsferruzza/" title="Get my GPG key on my Keybase profile" className="icon is-large keybase" target="_blank" rel="nofollow noopener">
              <FontAwesomeIcon size="3x" icon={faKeybase} />
            </a>
          </div>
        </div>
      </section>
    </div>
    <div className="container is-narrow">
      <section className="section">
        <h1 className="title">Recent posts</h1>
        <ul className="post-list">{posts}</ul>
        <Link to="/blog" className="button is-primary">Archives</Link>
      </section>
    </div>
  </Layout>;
};

export default HomePage;
